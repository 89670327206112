import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  description?: string
  source?: string
  user?: string
}

export const Review = memo(function Review({
  description,
  source,
  user,
}: Props) {
  return (
    <Container>
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      <ReviewInfo dial={5} row>
        {user ? <User>{user}</User> : null}
        {user && source ? '\xa0-\xa0' : null}
        {source ? <Source>{source}</Source> : null}
      </ReviewInfo>
    </Container>
  )
})

const Container = styled.div``

const Description = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  line-height: 2.3125rem;
  overflow: hidden;
`

const ReviewInfo = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.25rem;
  margin-top: 2.25rem;
`

const User = styled.div``

const Source = styled.div``
